'use client'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@paladise/ui/components/ui/accordion'
import { Button } from '@paladise/ui/components/ui/button'
import { buttonVariants } from '@paladise/ui/components/ui/button.style'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@paladise/ui/components/ui/dialog'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import DownloadLink, { clickDownloadLink } from 'components/DownloadLink'
import Logo from 'components/Logo'
import {
  type MenuChild,
  type MenuItem,
  type MenuParent,
} from 'config/constants'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import React from 'react'
import { tv } from 'tailwind-variants'

const styles = tv({
  slots: {
    container: 'px-4',
    link: 'flex h-[36px] w-full items-center text-lg font-semibold',
  },
})

const { container, link } = styles()

const prevent = (e: any) => {
  console.log(e)
  e.preventDefault()
}

const ExpandableMenu = ({
  item,
  onClose,
}: {
  item: MenuParent
  onClose: () => void
}) => {
  return (
    <AccordionItem value={item.text}>
      <AccordionTrigger className={link()} iconClassName="text-label-l1">
        {item.text}
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-3">
        {item.children.map((child, index) => (
          <Link
            key={index}
            href={child.href}
            className={link({
              className: '[&:nth-child(1)]:mt-3',
            })}
            onClick={onClose}
          >
            {child.text}
          </Link>
        ))}
      </AccordionContent>
    </AccordionItem>
  )
}

const FullPageMenu = ({
  item,
  onOpenSubMenu,
}: {
  item: MenuParent
  onOpenSubMenu: (menuItems: MenuChild[]) => void
}) => {
  return (
    <button className={link()} onClick={() => onOpenSubMenu(item.children)}>
      <span>{item.text}</span>
      <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
    </button>
  )
}

export const LinkMenu = ({
  item,
  onClose,
}: {
  item: MenuChild
  onClose: () => void
}) => {
  return (
    <Link
      href={item.href}
      className={link()}
      onClick={e => {
        if (item.href === '#') {
          e.preventDefault()
          clickDownloadLink()
        }
        onClose()
      }}
    >
      <span>{item.text}</span>
      <span className="i-ps-chevron_right ml-auto block [--icon-size:18px]"></span>
    </Link>
  )
}

const MobileMenu = ({
  additionalContent,
  menuLinks,
}: {
  menuLinks: MenuItem[]
  additionalContent?: (onClose: () => void) => React.ReactNode
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const t = useTranslations()
  const [showMenus, setShowMenus] = React.useState(menuLinks)

  const onClose = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <Dialog
      open={isOpen}
      onOpenChange={open => {
        setIsOpen(open)
        if (!open) {
          setShowMenus(menuLinks)
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="label-l1" className={'p-0'}>
          <span
            className="i-ps-menu text-l-l1 [--icon-size:24px]"
            aria-hidden="true"
          />
        </Button>
      </DialogTrigger>

      <DialogContent
        position="none"
        defaultAnimation={false}
        className="data-[state=open]:animate-menu-in data-[state=closed]:animate-menu-out left-0 top-0 block h-dvh w-screen max-w-none transform-none overflow-y-auto rounded-none p-0"
        onInteractOutside={prevent}
        onPointerDownOutside={prevent}
      >
        <DialogTitle className="sr-only">Menu</DialogTitle>

        {/* Header */}
        <div className="h-mobile-fixed-top sticky top-0 z-10 flex items-center justify-between bg-white px-4">
          <DialogClose asChild>
            <Button variant="label-l1" size="none">
              <span className="i-ps-close [--icon-size:24px]" />
            </Button>
          </DialogClose>

          <Logo
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            onClick={onClose}
          />

          <DownloadLink
            className={buttonVariants({
              variant: 'outline',
              size: 'none',
              className: 'text-quote flex px-4 py-2',
            })}
          >
            {t('get_app')}
          </DownloadLink>
        </div>

        <nav>
          <Accordion type="multiple" className="mt-5 gap-3" asChild>
            <ul>
              {showMenus.map((item, index) => (
                <li key={index} className={container()}>
                  {'href' in item ? (
                    <LinkMenu item={item} onClose={onClose} />
                  ) : item.fullPage ? (
                    <FullPageMenu item={item} onOpenSubMenu={setShowMenus} />
                  ) : (
                    <ExpandableMenu item={item} onClose={onClose} />
                  )}
                </li>
              ))}
            </ul>
          </Accordion>
        </nav>

        {additionalContent?.(onClose)}
      </DialogContent>
    </Dialog>
  )
}

export default MobileMenu
